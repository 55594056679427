import React, {Fragment, useState, useRef} from 'react';
import { Button } from 'primereact/button';
import InfiniteScroll from 'react-infinite-scroller';
import StripedList from '../../UI/StripedList';
import InputField from '../Forms/InputField';
import {switchRole} from "../../shared/roles";
import Tag from "../../UI/Tag";
import '../../assets/css/Templates.css';
import '../../assets/css/CustomModalTemplate.css'
import {Dialog} from "primereact/dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {convertMimetypeType} from "../../shared/utility";
import ResultNotFount from '../../UI/ResultNotFount';

const Templates = ({ templates, queryString, onChangeQueryString, onTemplateSelection, onCloseModal, newTemplate,
                       editTemplate, deleteTemplate, scrollRef, gateway, whatsappTemplate, onChangeWhatsappTemplate,
                       templatesState, hasMoreTemplate, setTemplateState, showDialog, setShowDialog }) => {
    const role = switchRole();
    const scrollParentRef = useRef(null);
    const [hideDialog, setHideDialog] = useState(false)
    const [templateMedia, setTemplateMedia] = useState(null)

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    const getMoreTemplate = async () => {
        if(templatesState.searchQuery){
            setTemplateState((prevState) => {
                return {
                    ...prevState,
                    searchQuery: templatesState.searchQuery,
                    filteredPage: prevState.filteredPage + 1,
                }
            });
        } else {
            if (templatesState.templates.length !== templatesState.num_results){
                setTemplateState((prevState) => {
                    return {
                        ...prevState,
                        page: prevState.page + 1
                    }
                });
            }
        }
    }

    const templateList = templates.map((template) => {
        let deleteButton = null;
        let editButton = null;
        let whatsapp_template = template.whatsapp_template;

        if (role !== 'customer_support'){
            deleteButton = (<Button
                type="button"
                icon="pi pi-trash"
                className="p-button-rounded p-button-danger"
                style={{width: '2em', height: '2em'}}
                onClick={(event) => {
                    event.preventDefault();
                    deleteTemplate(template.id);
                }}
            />);
            if (!whatsapp_template){
                editButton = (<Button
                    type="button"
                    icon="pi pi-pencil"
                    className="p-button-rounded btn-actions-default"
                    style={{marginRight:'.25em', width: '2em', height: '2em'}}
                    onClick={(event) => {
                        event.preventDefault();
                        editTemplate(template.id);
                    }}
                />);
            }

        }

        const templateStatuses = {
            'APPROVED': 'Aprobado',
            'DELETED': 'Borrado',
            'DISABLED': 'Desactivado',
            'IN_APPEAL': 'En apelación',
            'PENDING': 'Pendiente',
            'PENDING_DELETION': 'Pendiente de eliminación',
            'REJECTED': 'Rechazado'
        }

        const showTemplateTag = template.whatsapp_template ? (
            <Tag>{templateStatuses[template.status]}</Tag>
        ) : null;

        const canSelectTemplate = (template.status === 'APPROVED' || !template.whatsapp_template);

        const templateValueSelection = (canSelectTemplate) ? (
            <a href='' onClick={(event) => {
                event.preventDefault()
                onTemplateSelection(template.body, template.whatsapp_template, template.template_uuid, template.template_header_config)
            }}>
                <h5><strong>{template.title} {showTemplateTag}</strong></h5>
            </a>

        ) : <h5><strong>{template.title} {showTemplateTag} </strong></h5>;

        const getIcon = (mimetype) => {
            let type_icon = convertMimetypeType(mimetype)
            if (type_icon === 'document'){
                type_icon = 'file-pdf'
            }
            return type_icon
        }

        const template_icon = template.template_header_config ? (
            getIcon(template.template_header_config.mimetype)
        ) : null

        const templateImg = template.template_header_config ? (
            <Fragment>
                <button data-tip data-for="imageButton"
                        onClick={(e) => {
                            setHideDialog(true);
                            setTemplateMedia(template.template_header_config.url);
                        }}
                        disabled={template_icon !== 'image'}
                        style={{width: '2em', height: '2em'}}
                        className={'p-button p-component p-button-rounded btn-actions-default p-button-icon-only template-value-img'}>
                    <FontAwesomeIcon icon={ 'fa-regular fa-' + template_icon} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                </button>
            </Fragment>
        ) : null;

        return (<Fragment>
            <div className={"template-value"}>
                {templateValueSelection}
                <span>{template.body}</span>
            </div>
            <div className={'right-item-list inline-flex'}>
                {templateImg}
                {editButton}
                {deleteButton}
            </div>
        </Fragment>);
    })

    const addNewTemplateButton = role === 'customer_support' ? null : (
        <Button
            className="ml-auto"
            label="Nuevo"
            icon='pi pi-plus'
            onClick={() => newTemplate()}
        />
    )

    const header = (
        <Fragment>
            <div className="custom-modal-header">
                <div className="custom-modal-header-title">
                    Respuestas Guardadas
                </div>
            </div>
        </Fragment>
    );

    const body = (
        <Fragment>
            <div className="flex p-fluid">
                <div className={gateway === 'DIALOG_360' || gateway === 'CLOUD-API' ? 'col-10' : 'col-12'}>
                    <InputField
                        type={'text'}
                        value={queryString === null ? '' : queryString}
                        traduction={'Buscar'}
                        onChange={onChangeQueryString}/>
                </div>

                {(gateway === 'DIALOG_360' || gateway === 'CLOUD-API') && (
                    <div className="col-2">
                        <InputField
                            type={'switch'}
                            value={whatsappTemplate}
                            disabled={!(gateway === 'DIALOG_360' || gateway === 'CLOUD-API')}
                            traduction={'Plantilla de Whatsapp'}
                            onChange={onChangeWhatsappTemplate}
                        />
                    </div>
                )}

            </div>
            {(templates.length === 0)  && (
                <ResultNotFount/>
            )}
            <div className='scrollable' style={{height: "73%"}} ref={scrollRef}>
                <InfiniteScroll
                    pageStart={templatesState.page}
                    loadMore={() => getMoreTemplate()}
                    isReverse={false}
                    threshold={1}
                    initialLoad={false}
                    useWindow={false}
                    hasMore={hasMoreTemplate}
                    getScrollParent={() => scrollParentRef.current}
                >
                    <StripedList items={templateList}/>
                </InfiniteScroll>
                <Dialog style={{ width: 'auto', height: 'auto'}} visible={hideDialog} onHide={(e) => {
                    setHideDialog(false);
                    setTemplateMedia(null);
                }}>
                    <img style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }}
                         src={templateMedia} alt={'multimedia'}></img>
                </Dialog>
            </div>
        </Fragment>
    );


    return (
        <Dialog icons={addNewTemplateButton} header={header} visible={showDialog} style={{ width: isMobile() ? '100vw' : '50vw' }} onHide={() => setShowDialog(false)}>
            <Fragment>
                <div className="custom-modal-body">
                    {body}
                </div>
            </Fragment>
        </Dialog>
    );

}

export default Templates;


